import { NextSeo } from "next-seo";
import Image from "next/image";

export default function NotFound() {
  return (
    <>
      <NextSeo title={`Error - Monokel`} />
      <div className="Grid Grid-error">
        <div className="Grid-block Grid-block--large">
          <Image src="/img/error.jpeg" alt="404 - Monokel" layout="fill" />
        </div>
        <div className="Grid-block Grid-block--large">
          <div className="Grid">
            <div className="Grid-block">
              <p
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                404
                <br />
                Sorry we couldn't find the page
              </p>
              <a href="/">Go back to startpage</a>
            </div>
            <div className="Grid-block">
              <p
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                404
                <br />
                Sorry we couldn't find the page
              </p>
              <a href="/">Go back to startpage</a>
            </div>
            <div className="Grid-block">
              <p
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                404
                <br />
                Sorry we couldn't find the page
              </p>
              <a href="/">Go back to startpage</a>
            </div>
            <div className="Grid-block">
              <p
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                404
                <br />
                Sorry we couldn't find the page
              </p>
              <a href="/">Go back to startpage</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
